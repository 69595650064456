import React, {useContext} from "react";
import {Link} from 'react-router-dom';
import Markdown from "react-markdown";
import WorkContext from "../context/WorkContext";
import Highlight from "react-highlight.js";
import styled from "styled-components";
import BackLinkContainer from "./BackLinkContainer";
const WorkContainer = styled.main`
    display: flex;
    flex-wrap: wrap;
`;
const WorkTitle = styled.h1`
    font-family: "Lora", serif;
    text-align: center;
    font-size: 3em;
    width: 100%;
`;
const MarkdownContainer = styled.section`
    max-height: 83vh;
    box-sizing: border-box;
    padding: 3%;
    overflow-y: scroll;
    width: 50%;
    @media screen and (max-aspect-ratio: 924/768) {
        width: 100%;
    }
`;
const DemoContainer = styled.div`
    height: 83vh;
    width: 50%;
    display: flex;
    place-content: center;
    place-items: center;
    box-sizing: border-box;
    @media screen and (max-aspect-ratio: 924/768) {
        display: none;
    }
`;
const Demo = styled.iframe`
    box-sizing: border-box;
    display: block;
    border: none;
    width: 80%;
    height: 100%;
    background-color: white;
`
function CodeBlock({language, value}) {
    return (
         <Highlight language={language}>
             {value}
         </Highlight>
     )
}
function Work(props){
    let allWork = useContext(WorkContext);
    let work = allWork[props.match.params.slug]
    let title = work ? work.fields.title : "";
    let markdown = work ? work.fields.markdown : "&nbsp;";
    let demoUrl = work ? work.fields.demoUrl : "";
    return  (
      <WorkContainer>
          <BackLinkContainer>
            <Link to="/work">
                &lt; Back
            </Link>
          </BackLinkContainer>
      <WorkTitle>{title}</WorkTitle>
      <DemoContainer>
        <Demo src={demoUrl}></Demo>
      </DemoContainer>
      <MarkdownContainer>
        <Markdown 
            source={markdown}
            renderers={{
            code: CodeBlock,
            paragraph: styled.p`font-family: "Open Sans" sans-serif`,
            heading: ({level, children})=>{
                let Heading = styled[`h${level}`]`
                    font-family: "Lora", serif;
                `
                return (<Heading>
                    {children}
                </Heading>)
            }
            }}
        />
      </MarkdownContainer>
      </WorkContainer>
    )
  }

export default Work;