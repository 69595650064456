import React, {useEffect, useState} from 'react';
import "./App.css";
import "./highlight-theme.css";
import BackLinkContainer from "./components/BackLinkContainer";
import WorkContext from "./context/WorkContext";
import Work from "./components/Work";
import styled, {createGlobalStyle} from "styled-components";
import { BrowserRouter, Route, Link} from 'react-router-dom';

const contentful = require("contentful");
const client = contentful.createClient({
  space: "2h4arjm3pof1",
  accessToken: "jmjxFmRojxuDqFBKrl81iVIG_uAw7V_zEmnys6uhspM"
});

let Fonts = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lora|Open+Sans&display=swap');
  h1, h2, h3, h4, p, a{
    color: #333;
  }
  html{
    font-size: 2vh;
    color: #333;
  }
 .App{
    transition: opacity ease 0.5s;
  }
`
const Title=styled.h1`
  font-family: "Lora", serif;
  font-size: 5.15em;
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 2.6em;
  margin-bottom: 0;
  font-weight: 600;
`
const Subtitle = styled.p`
  font-family: "Open Sans", sans-serif;
  display: block;
  text-align: center;
  width: 100%;
  font-size: 1.75em;
  margin-top: 1em;
  font-weight: 100;
`
const Home = styled.section`
  width: 100vw;
  /* height:100vh; */
  flex-wrap: wrap;
`


const StyledA = styled.p`
  font-family: "Open Sans", sans-serif;
  text-align: center;
  width: 100%;
  display: block;
  text-decoration: underline;
  font-size: 1.75em;
  font-weight: 100;
  line-height: 1.3;
  cursor: pointer;
  margin: 0;
  margin-top: 0.3em;
`

const LinksContainer = styled.section`
  margin-top: 4.75em;
`

const WorkThumbnailContainer = styled.section`
  margin: 2.5em 8.5% 0 8.5%;
  padding: 1% 0.1%;
  display: flex;
  flex-wrap: wrap;
  min-height: 16.75em;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  @media screen and (max-aspect-ratio: 1170/768) {
    margin: 2.5em 4.5% 0 4.5%;
  }
  @media screen and (max-aspect-ratio: 980/768) {
    margin: 2.5em 0.5% 0 0.5%;
  }
  a{
    margin-top: 3em;
    text-decoration: none;
    box-sizing: border-box;
    box-shadow: 0px 0px 25px -3px #333c;
    display: block;
    min-height: 16.75em;
    width: 21.5%;
    padding: 1%;
  }

  @media screen and (max-aspect-ratio: 900/768) {
    margin: 2.5em 4.5% 0 4.5%;
    a{
      width: 31.5%;
    }
  }

  @media screen and (max-aspect-ratio: 750/768) {
    a{
      width: 46.5%;
    }
  }
  @media screen and (max-aspect-ratio: 600/768) {
    a{
      width: 90%;
      min-height: 6.75em;
    }
  }
`

const WorkThumbnailCard = styled.section`
  display: block;
  height: 100%;
`

const ThumbnailCardImage = styled.section`
  background-color: #ccc;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 10em;
  @media screen and (max-aspect-ratio: 480/768) {
    width: 50%;
    height: 5em;
    float: right;
  }

`

const ThumbnailCardTitle = styled.h2`
  margin: 0;
  font-family: "Lora", serif;
  font-size: 1.5em;
  margin-top: 0.3em;
`;

const ThumbnailCardSubtitle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  margin: 0;
  margin-top: 0.3em;
`;

function Analytics(props){
  useEffect(()=>{
    console.log(props);
    if (typeof window.ga === 'function' && props.location) {
      window.ga('set', 'page', props.location.pathname + props.location.search);
      window.ga('send', 'pageview');
    }
  })
    return null;
}

function App() {
  let [fontsReady, updateFontsReady] = useState(false);
  let [work, updateWork] = useState({});
  let [entries, updateEntries] = useState([]);
  useEffect(()=>{
    if ("fonts" in document && "ready" in document.fonts){
      document.fonts.ready.then(()=>{
        updateFontsReady(true);
      });
    } else{
      setTimeout(()=>{
        updateFontsReady(true)
      }, 500);
    }
  },[updateFontsReady]);

  useEffect(()=>{
    client.getEntries().then(entries=>{
      console.log(entries);
      updateEntries(entries.items);
      updateWork(entries.items.reduce((allWork, entry)=>{
        allWork[entry.fields.slug] = {
          ...entry
        }
        return allWork;
      }, {}));
    });
  },
  [updateEntries, updateWork])

  
  return (
    <BrowserRouter>
    <WorkContext.Provider value={work}>
      <div className="App" style={{"opacity": fontsReady? 1:0}}>
      {/* <GuideImage src={guide}></GuideImage> */}
        <Fonts></Fonts>
        <Route exact path={["/", "/work", "/contact"]}>
        <Home>

          <Route exact path={["/work", "/contact"]}>
            <BackLinkContainer>
              <Link to="/">&lt; Back</Link>
            </BackLinkContainer>
          </Route>
          <Title>Philip Hickey</Title>
          <Subtitle>Developer</Subtitle>
          <Route exact path="/">
            <LinksContainer>
              {/* <Link to="/work">
                <StyledA>Work</StyledA>
              </Link> */}
              <Link to="/contact">
                <StyledA>Contact</StyledA>
              </Link>
              <a href="/resume/Philip%20Hickey.pdf">
              <StyledA >Curriculum Vitae</StyledA>
              </a>
            </LinksContainer>
          </Route>
          <Route exact path="/contact">
            <LinksContainer>
                <a href="https://www.linkedin.com/in/philip-hickey/" target="_blank" rel="noopener noreferrer">
                  <StyledA >LinkedIn</StyledA>
                </a>
                <a href="mailto:hello@p.hiliphickey.com" target="_blank" rel="noopener noreferrer">
                  <StyledA > Email </StyledA>
                </a>
                <a href="tel:+19292652026‬">
                  <StyledA >Phone</StyledA>              
                </a>
                <a href="https://calendly.com/philip-hickey/call‬">
                  <StyledA >Schedule a meeting</StyledA>              
                </a>
                </LinksContainer>
          </Route>
          <Route exact path="/work">
              <WorkThumbnailContainer>
              {entries.map((e,idx)=>{
                return(
                  <Link to={`/work/${e.fields.slug}`} key={idx}>
                  <WorkThumbnailCard>
                    <ThumbnailCardImage style={{backgroundImage: `url(${e.fields.thumbnail.fields.file.url}`}} />
                    <ThumbnailCardTitle>{e.fields.title}</ThumbnailCardTitle>
                    <ThumbnailCardSubtitle>{e.fields.subtitle}</ThumbnailCardSubtitle>
                  </WorkThumbnailCard>
                  </Link>
                )
              })}
              </WorkThumbnailContainer>
          </Route>
        </Home>
        </Route>
        <Route exact path="/work/:slug" component={Work}/>
      </div>
    </WorkContext.Provider>
    </BrowserRouter>
  );
}



export default App;
